import React, { ReactElement } from 'react';
import AppString from '../String';
import './style.scss';
// import Dropdown from 'react-dropdown';
// import { languages } from '../../data';
import { LanguageKeys, Strings } from '../../lib/types';

const Header = ({
    onHome,
    // onLang,
    title,
    lang,
    dark = false,
}: {
    onHome: () => void;
    // onLang: (langData: any) => void;
    title?: Strings;
    lang: LanguageKeys;
    dark?: boolean;
}): ReactElement => {
    const headerClass = dark
        ? 'Header Header--primary'
        : 'Header Header--white';

    const logoUrl = dark ? 'assets/img/logo-dark.png' : 'assets/img/logo.png';

    return (
        <div className={headerClass}>
            <span role="button" onClick={onHome}>
                <img
                    className="Header__logo"
                    src={logoUrl}
                    alt="Michelin Logo"
                />
            </span>
            {title && (
                <h2 className="Header__title text-tilting-bold">
                    <AppString k={title} lang={lang} />
                </h2>
            )}
            <div className="Header__home">
                {/*<Dropdown options={languages} onChange={onLang} value={lang} />*/}
                <div role="button" className="Button--home" onClick={onHome} />
            </div>
        </div>
    );
};

export default Header;
