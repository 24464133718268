import {
    AppAction,
    AppActions,
    AppState,
    CardBucketData,
    CardBucketIds,
    CardBuckets,
    CardData,
    CoinBucketData,
    CoinBucketIds,
    CoinBuckets,
    GameState,
    LanguageKeys,
    ModalData,
} from './types';
import { initCardBuckets, initCoinBuckets } from '../data';
import { areAllCardsMoved, areAllCoinsMoved, shuffleArray } from './helpers';

export const initializeState = (): AppState => {
    initCardBuckets[CardBucketIds.SRC].cards = shuffleArray(
        initCardBuckets[CardBucketIds.SRC].cards,
    ) as CardData[];

    return JSON.parse(
        JSON.stringify({
            cardBuckets: initCardBuckets,
            coinBuckets: initCoinBuckets,
            modalOpen: false,
            modalContent: {} as ModalData,
            activeScreen: 0,
            currentScreen: 0,
            cardGameState: GameState.CARDS_INIT,
            coinGameState: GameState.COINS_INIT,
            questionGameState: GameState.CARDS_INIT,
            lang: LanguageKeys.EN,
        } as AppState),
    );
};

export const reducer = (state: AppState, action: AppAction): AppState => {
    let buckets, bucketSrc, bucketDest, item;
    switch (action.type) {
        case AppActions.CHECK_CARDS:
            buckets = Object.keys(state.cardBuckets).reduce((acc, k) => {
                const bucket = state.cardBuckets[k as CardBucketIds];
                const cards = bucket.cards.map((card) => {
                    card.correct = card.allowedBuckets.includes(
                        k as CardBucketIds,
                    );
                    return card;
                });
                acc[k as CardBucketIds] = {
                    ...bucket,
                    cards: cards,
                };
                return acc;
            }, {} as CardBuckets);
            return {
                ...state,
                cardBuckets: buckets,
                cardGameState: GameState.CARDS_CHECKED,
            };

        case AppActions.CHECK_COINS:
            buckets = Object.keys(state.coinBuckets).reduce((acc, k) => {
                const bucket = state.coinBuckets[k as CoinBucketIds];
                const coinValue = bucket.coins.reduce(
                    (acc, c) => acc + c.value,
                    0,
                );
                acc[k as CoinBucketIds] = {
                    ...bucket,
                    isCorrect: coinValue === bucket.correctValue,
                };
                return acc;
            }, {} as CoinBuckets);
            return {
                ...state,
                coinBuckets: buckets,
                coinGameState: GameState.COINS_CHECKED,
            };

        case AppActions.CARDS_RESET:
            return {
                ...initializeState(),
                swiper: state.swiper,
            };

        case AppActions.REORDER_CARDS:
            bucketDest = state.cardBuckets[action.bucketId as CardBucketIds];
            [item] = bucketDest.cards.splice(action.idxFrom, 1);
            bucketDest.cards.splice(action.idxTo, 0, item);
            return {
                ...state,
                cardBuckets: {
                    ...state.cardBuckets,
                    [action.bucketId as CardBucketIds]: {
                        ...bucketDest,
                        cards: [...bucketDest.cards],
                        maxCards: bucketDest.maxCards,
                    } as CardBucketData,
                },
            };

        case AppActions.MOVE_CARD:
            bucketSrc = state.cardBuckets[action.bucketIdFrom as CardBucketIds];
            bucketDest = state.cardBuckets[action.bucketIdTo as CardBucketIds];
            [item] = bucketSrc.cards.splice(action.idxFrom, 1);
            bucketDest.cards.splice(action.idxTo, 0, item);
            buckets = {
                ...state.cardBuckets,
                [action.bucketIdFrom as CardBucketIds]: {
                    ...bucketSrc,
                    cards: [...bucketSrc.cards],
                    maxCards: bucketSrc.maxCards,
                    isFull: false,
                } as CardBucketData,
                [action.bucketIdTo as CardBucketIds]: {
                    ...bucketDest,
                    cards: [...bucketDest.cards],
                    maxCards: bucketDest.maxCards,
                    isFull: bucketDest.cards.length === bucketDest.maxCards,
                } as CardBucketData,
            };
            return {
                ...state,
                cardBuckets: buckets,
                cardGameState: areAllCardsMoved(buckets)
                    ? GameState.CARDS_ALL_MOVED
                    : GameState.CARDS_MOVED,
            };

        case AppActions.MOVE_COIN:
            bucketSrc = state.coinBuckets[action.bucketIdFrom as CoinBucketIds];
            bucketDest = state.coinBuckets[action.bucketIdTo as CoinBucketIds];
            [item] = bucketSrc.coins.splice(action.idxFrom, 1);
            bucketDest.coins.splice(action.idxTo, 0, item);
            buckets = {
                ...state.coinBuckets,
                [action.bucketIdFrom as CoinBucketIds]: {
                    ...bucketSrc,
                    coins: [...bucketSrc.coins],
                    maxCoins: bucketSrc.maxCoins,
                } as CoinBucketData,
                [action.bucketIdTo as CardBucketIds]: {
                    ...bucketDest,
                    coins: [...bucketDest.coins],
                    maxCoins: bucketDest.maxCoins,
                } as CoinBucketData,
            };
            return {
                ...state,
                coinBuckets: buckets,
                coinGameState: areAllCoinsMoved(buckets)
                    ? GameState.COINS_ALL_MOVED
                    : GameState.COINS_MOVED,
            };

        case AppActions.USER_ANSWER:
            bucketSrc = state.cardBuckets[action.bucketId];
            buckets = {
                ...state.cardBuckets,
                [action.bucketId]: {
                    ...bucketSrc,
                    questions: bucketSrc.questions.map((question, idx) => {
                        if (action.questionIdx !== idx) {
                            return question;
                        }
                        return {
                            ...question,
                            userAnswer: question.answers[action.answerIdx],
                            isCorrect:
                                question.correctAnswer ===
                                question.answers[action.answerIdx],
                        };
                    }),
                } as CardBucketData,
            };

            buckets[action.bucketId].questionGameState = buckets[
                action.bucketId
            ].questions.every((q) => q.userAnswer)
                ? GameState.QUESTION_ALL_ANSWERED
                : GameState.QUESTION_ANSWERED;

            return {
                ...state,
                cardBuckets: buckets,
            };

        case AppActions.CHECK_QUESTIONS:
            bucketSrc = state.cardBuckets[action.bucketId];
            buckets = {
                ...state.cardBuckets,
                [action.bucketId]: {
                    ...bucketSrc,
                    questionGameState: GameState.QUESTION_CHECKED,
                } as CardBucketData,
            };

            return {
                ...state,
                cardBuckets: buckets,
            };

        case AppActions.MODAL_OPEN:
            return {
                ...state,
                modalOpen: true,
                modalContent: action.content,
            };

        case AppActions.MODAL_CLOSE:
            return {
                ...state,
                modalOpen: false,
            };

        case AppActions.SET_SWIPER:
            return {
                ...state,
                swiper: action.swiper,
            };

        case AppActions.NEXT_SCREEN:
            return {
                ...state,
                currentScreen: state.currentScreen + 1,
            };

        case AppActions.PREV_SCREEN:
            return {
                ...state,
                currentScreen: Math.max(0, state.currentScreen - 1),
            };

        case AppActions.SET_LANG:
            return {
                ...state,
                lang: action.lang,
            };

        default:
            return state;
    }
};
