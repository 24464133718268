import { TranslationKeys } from '../lib/types';

export default {
    [TranslationKeys.idle_title]: 'Corporate<br />carbon<br />footprint<br />game',
    [TranslationKeys.idle_intro]: 'To set smart climate-targets that achieve meaningful emissions reductions, we first need to understand were emissions come from.',
    [TranslationKeys.idle_description]: 'In this game we will explore the carbon footprint of a global fashion brand with a broad network of textile producers, garment manufacturers, transportation providers, wholesalers and retailers. Let\'s learn about the various steps along the fashion brand\'s value chain and discover where the majority of emissions come from.',
    [TranslationKeys.idle_cta]: 'PLAY',
    // [TranslationKeys.intro_title]: 'Our Digital World',
    // [TranslationKeys.intro_description]: 'Take a look around you, you probably see a computer, a phone, a charger, a cable... But what about all the invisible components and activities that are needed to make our digital world work? To assess the carbon footprint of our digital work day, we need to organize all these components and activities into life cycle stages.\nHere you can see 4 of the main life cycle stages of a digital carbon footprint.',
    // [TranslationKeys.intro_cta]: 'Next',
    [TranslationKeys.game_controls_check]: 'CHECK',
    [TranslationKeys.game_controls_next]: 'Next',
    [TranslationKeys.game_controls_finish]: 'Finish',
    [TranslationKeys.game_drag_card_title]: '1. BUILD THE VALUE CHAIN STEPS',
    [TranslationKeys.game_drag_card_description]: '<strong>Can you guess which of the activity cards are a significant part of the 3 value chain steps?</strong>\n\nPlace the cards accordingly.',
    [TranslationKeys.game_drag_card_description_static]: 'Correct supply chain stages & activities.',
    [TranslationKeys.game_drag_card_begin]: 'Start dragging a card',
    [TranslationKeys.game_drag_card_more]: 'Fill all available spots',
    [TranslationKeys.game_drag_card_result]: 'The incorrect cards are marked in red.\nClick on NEXT to find out why',
    [TranslationKeys.game_drag_card_result_comment]: 'Click on the cards to learn more about each activity',
    [TranslationKeys.game_drag_coin_title]: '2. GUESS THE CARBON FOOTPRINT',
    [TranslationKeys.game_drag_coin_description]: 'How much does each activity contribute towards the total carbon footprint? <strong>Drag the percentage coins to the appropriate stages below.</strong>',
    [TranslationKeys.game_drag_coin_description_static]: 'Correct average carbon footprint of each stage.',
    [TranslationKeys.game_drag_coin_begin]: 'Start dragging a coin...',
    [TranslationKeys.game_drag_coin_more]: 'Drag all available coins',
    [TranslationKeys.game_drag_coin_result]: 'The incorrect responses are marked in red.\nClick on NEXT to see the correct carbon footprint.',
    [TranslationKeys.game_drag_coin_result_comment]: 'Ready to test your knowledge further?\nClick on NEXT to learn more!',
    [TranslationKeys.game_questions_title]: '3. LEARN ABOUT THE IMPACTS',
    [TranslationKeys.game_questions_description]: 'Test your knowledge on the impact of specific activities along the 3 value chain stages.\n',
    [TranslationKeys.game_questions_instruction]: 'Click on the answer you think is correct...',
    [TranslationKeys.game_questions_result_correct]: 'That\'s correct! Move to the next step',
    [TranslationKeys.game_questions_result_wrong]: 'Nice guess! Move to the next step',
    // [TranslationKeys.game_recap_title]: 'THE LIFE CYCLE CARBON IMPACT OF OUR DIGITAL WORLD',
    // [TranslationKeys.game_recap_description]: '',
    // [TranslationKeys.game_recap_next]: 'GO TO TIPS',
    [TranslationKeys.outro_title]: 'THANKS FOR PLAYING!',
    [TranslationKeys.outro_intro]: '<strong>The corporate footprint of a company includes emissions from both owned operations and from the various partners and activities along your value chain.</strong>\n\nIn Module 2 of LEAP for Climate, we will cover the benefits of measuring your company\'s corporate carbon footprint and share step-by-step guidance on how to build an inventory of all greenhouse gas emissions.\n\n<strong>You can now close this tab and return to Module 2 to continue your learning journey.</strong>',
    // [TranslationKeys.outro_cta]: 'LEARN MORE',
    // [TranslationKeys.outro_url]: '/assets/doc/20210423_Digital_Frugality_EN.pdf',
    [TranslationKeys.data_card_0_content]: 'Purchase of products',
    [TranslationKeys.data_card_0_modal_title]: 'Purchase of products',
    [TranslationKeys.data_card_0_modal_content]: 'Production of products purchased or acquired by the fashion brand, including the extraction and processing of raw materials and transformation into the finished product.',
    [TranslationKeys.data_card_1_content]: 'Transportation of products to your facility',
    [TranslationKeys.data_card_1_modal_title]: 'Transportation of products to your facility',
    [TranslationKeys.data_card_1_modal_content]: 'Transportation and distribution of products purchased between the company’s suppliers and its own operations in vehicles not owned or operated by the fashion brand. ',
    [TranslationKeys.data_card_2_content]: 'Waste generated at manufacturing plants',
    [TranslationKeys.data_card_2_modal_title]: 'Waste generated at manufacturing plants',
    [TranslationKeys.data_card_2_modal_content]: 'Disposal and treatment of waste in facilities owned or operated by third parties, including the disposal of both solid waste and wastewater.',
    [TranslationKeys.data_card_3_content]: 'Construction of machinery to be used at your owned facility',
    [TranslationKeys.data_card_3_modal_title]: 'Construction of machinery to be used at your owned facility',
    [TranslationKeys.data_card_3_modal_content]: 'Purchase of capital goods and fixed assets with an extended life (e.g. company vehicles, equipment, machinery).',
    [TranslationKeys.data_card_4_content]: 'Employee commuting',
    [TranslationKeys.data_card_4_modal_title]: 'Employee commuting',
    [TranslationKeys.data_card_4_modal_content]: 'Transportation of employees between their homes and their worksites, including automobile travel, bus travel, rail travel and air travel.',
    [TranslationKeys.data_card_5_content]: 'Rented warehouse to store your products',
    [TranslationKeys.data_card_5_modal_title]: 'Rented warehouse to store your products',
    [TranslationKeys.data_card_5_modal_content]: 'The lease or rent of assets such as office buildings or warehouses by the fashion brand.',
    [TranslationKeys.data_card_6_content]: 'Fuel purchased to power your company vehicles',
    [TranslationKeys.data_card_6_modal_title]: 'Fuel purchased to power your company vehicles',
    [TranslationKeys.data_card_6_modal_content]: 'The burning of fuel to power your owned and controlled vehicles.',
    [TranslationKeys.data_card_7_content]: 'Electricity used in your owned offices',
    [TranslationKeys.data_card_7_modal_title]: 'Electricity used in your owned offices',
    [TranslationKeys.data_card_7_modal_content]: 'The generation of purchased energy, from a utility provider, for use in your owned facilities.',
    [TranslationKeys.data_card_8_content]: 'Use of sold products by customers',
    [TranslationKeys.data_card_8_modal_title]: 'Use of sold products by customers',
    [TranslationKeys.data_card_8_modal_content]: 'The use of sold products by end-consumers, including washing, drying and ironing.',
    [TranslationKeys.data_card_9_content]: 'Disposal of products at the end of their life',
    [TranslationKeys.data_card_9_modal_title]: 'Disposal of products at the end of their life',
    [TranslationKeys.data_card_9_modal_content]: 'Waste disposal and treatment of products at the end of their life (e.g., landfilling, incineration, and recycling)',
    [TranslationKeys.data_card_10_content]: 'Transport of products from retail store to consumer\'s homes',
    [TranslationKeys.data_card_10_modal_title]: 'Transport of products from retail store to consumer\'s homes',
    [TranslationKeys.data_card_10_modal_content]: 'Transportation and distribution of sold products in vehicles and facilities not owned by the fashion brand.',
    [TranslationKeys.data_card_11_content]: 'Production of pens for use at your owned office.',
    [TranslationKeys.data_card_11_modal_title]: '',
    [TranslationKeys.data_card_11_modal_content]: '',
    [TranslationKeys.data_bucket_a_title]: 'Activities required<br />to produce your products',
    [TranslationKeys.data_bucket_a_instruction]: 'Drag here',
    [TranslationKeys.data_bucket_a_question_a_text]: 'What contributes most to this stage?',
    [TranslationKeys.data_bucket_a_question_a_source]: '',
    [TranslationKeys.data_bucket_a_outro_modal_subtitle]: 'TBD',
    [TranslationKeys.data_bucket_a_outro_modal_title]: 'TBD',
    [TranslationKeys.data_bucket_a_outro_modal_item_a]: 'TBD',
    [TranslationKeys.data_bucket_a_outro_modal_item_b]: 'TBD',
    [TranslationKeys.data_bucket_a_outro_modal_item_c]: 'TBD',
    [TranslationKeys.data_bucket_b_title]: 'Activities at<br />your owned facility',
    [TranslationKeys.data_bucket_b_instruction]: 'Drag here',
    [TranslationKeys.data_bucket_b_question_a_text]: 'What activity is likely to have the biggest impact on reducing emissions at your owned facilities?',
    [TranslationKeys.data_bucket_b_question_a_source]: '',
    [TranslationKeys.data_bucket_b_outro_modal_subtitle]: 'TBD',
    [TranslationKeys.data_bucket_b_outro_modal_title]: 'TBD',
    [TranslationKeys.data_bucket_b_outro_modal_item_a]: 'TBD',
    [TranslationKeys.data_bucket_b_outro_modal_item_b]: 'TBD',
    [TranslationKeys.data_bucket_b_outro_modal_item_c]: 'TBD',
    [TranslationKeys.data_bucket_c_title]: 'Activities required<br />to consume your products',
    [TranslationKeys.data_bucket_c_instruction]: 'Drag here',
    [TranslationKeys.data_bucket_c_question_a_text]: 'What contributes most to this stage?',
    [TranslationKeys.data_bucket_c_question_a_source]: '',
    [TranslationKeys.data_bucket_c_outro_modal_subtitle]: 'TBD',
    [TranslationKeys.data_bucket_c_outro_modal_title]: 'TBD',
    [TranslationKeys.data_bucket_c_outro_modal_item_a]: 'TBD',
    [TranslationKeys.data_bucket_c_outro_modal_item_b]: 'TBD',
    [TranslationKeys.data_bucket_c_outro_modal_item_c]: 'TBD',
    [TranslationKeys.data_coin_bucket_a_title]: 'Activities required to produce your products',
    [TranslationKeys.data_coin_bucket_a_content]: 'Emissions associated with the extraction of raw materials and the processing of these raw materials into finished products represent the highest portion of total value chain emissions.',
    [TranslationKeys.data_coin_bucket_b_title]: 'Activities at your owned facility',
    [TranslationKeys.data_coin_bucket_b_content]: 'Direct energy emissions and the use of electricity at owned facilities typically represent less than 5% of total value chain emissions.',
    [TranslationKeys.data_coin_bucket_c_title]: 'Activities required to consume your products',
    [TranslationKeys.data_coin_bucket_c_content]: 'Emissions associated with the use & disposal of sold products typically represents up to 25% of total value chain emissions.',
};
