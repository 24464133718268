import AppString from '../../components/String';
// import ActualStrings from '../../strings';
import React, {
    // Dispatch,
    ReactElement,
} from 'react';
import Header from '../../components/Header';
import './style.scss';
import {
    // AppAction,
    // AppActions,
    // CardBucketIds,
    // CardBuckets,
    LanguageKeys,
    Strings,
} from '../../lib/types';
// import { cardBucketsWhere } from '../../lib/helpers';

// Helper function.
// const outroCards = (
//     buckets: CardBuckets,
//     dispatch: Dispatch<AppAction>,
//     lang: LanguageKeys,
// ): ReactElement => {
//     return (
//         <>
//             {Object.keys(buckets).map((k) => {
//                 const bucket = buckets[k as CardBucketIds];
//                 return (
//                     <div
//                         key={k}
//                         onClick={() =>
//                             dispatch({
//                                 type: AppActions.MODAL_OPEN,
//                                 content: bucket.outroBox.modalData,
//                             })
//                         }
//                         className="ViewOutro__box"
//                     >
//                         <div className="ViewOutro__box-inner">
//                             <h2 className="ViewOutro__box-title">
//                                 {bucket.outroBox.modalData.title[lang]}
//                             </h2>
//                             <div>
//                                 <span
//                                     role="button"
//                                     className="ViewOutro__info-btn"
//                                 >
//                                     i
//                                 </span>
//                             </div>
//                         </div>
//                         <div
//                             className={
//                                 'ViewOutro__box-spacer img-' +
//                                 bucket.outroBox.imgClassName
//                             }
//                         />
//                     </div>
//                 );
//             })}
//         </>
//     );
// };

const ViewOutro = ({
    onHome,
    // onLang,
    lang,
    // dispatch,
    // cardBuckets,
}: {
    onHome: () => void;
    // onLang: (langData: any) => void;
    lang: LanguageKeys;
    // dispatch: Dispatch<AppAction>;
    // cardBuckets: CardBuckets;
}): ReactElement => {
    // const bucketsLeft = cardBucketsWhere(cardBuckets, [
    //     CardBucketIds.DEST_A,
    //     CardBucketIds.DEST_B,
    // ]);
    // const bucketsRight = cardBucketsWhere(cardBuckets, [CardBucketIds.DEST_C]);

    return (
        <div className="ViewOutro">
            <div className="container-vertical">
                <div className="container">
                    <Header
                        title={Strings.OUTRO_TITLE}
                        onHome={onHome}
                        // onLang={onLang}
                        lang={lang}
                    />
                    <div className="ViewOutro__intro-row row">
                        <div className="col">
                            <div className="intro-text">
                                <p>
                                    <AppString
                                        k={Strings.OUTRO_INTRO}
                                        lang={lang}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*<div className="ViewOutro__box-row row">*/}
                    {/*    <div className="col-6">*/}
                    {/*        {outroCards(bucketsLeft, dispatch, lang)}*/}
                    {/*    </div>*/}
                    {/*    <div className="col-6">*/}
                    {/*        {outroCards(bucketsRight, dispatch, lang)}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <img
                        className="ViewOutro__img"
                        src="assets/img/mascot_full.svg"
                        alt=""
                    />
                </div>
                {/*<div className="ViewOutro__button-wrapper">*/}
                {/*    <div className="ViewOutro__button">*/}
                {/*        <a*/}
                {/*            role="button"*/}
                {/*            className="Button"*/}
                {/*            href={ActualStrings[lang][Strings.OUTRO_URL]}*/}
                {/*            target="_blank"*/}
                {/*            rel="noopener noreferrer"*/}
                {/*        >*/}
                {/*            <AppString k={Strings.OUTRO_CTA} lang={lang} />*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default ViewOutro;
