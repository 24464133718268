import Swiper from 'swiper';

export enum LanguageKeys {
    EN = 'en',
}

export type MultiLanguageString = { [l in LanguageKeys]: string };

export enum BucketTypes {
    COIN = 'bucket_coin',
    CARD = 'bucket_card',
}

export type CardData = {
    id: string;
    content: MultiLanguageString;
    allowedBuckets: CardBucketIds[];
    correct?: boolean;
    modalContent: ModalData;
};

export type CoinData = {
    id: string;
    value: number;
};

export type ModalData = {
    title: MultiLanguageString;
    subtitle?: MultiLanguageString;
    content?: MultiLanguageString;
    image?: string;
    isListView?: boolean;
    listItems?: { item: MultiLanguageString; icon: string }[];
};

export type OutroBoxData = {
    imgClassName: string;
    modalData: ModalData;
};

export enum CardBucketIds {
    SRC = 'a-src',
    DEST_A = 'a-a',
    DEST_B = 'a-b',
    DEST_C = 'a-c',
}

export type CardBucketData = {
    title?: MultiLanguageString;
    instruction?: MultiLanguageString;
    cards: CardData[];
    maxCards: number;
    isFull: boolean;
    questions: QuestionData[];
    questionGameState: GameState;
    outroBox: OutroBoxData;
};

export type CardBuckets = {
    [k in CardBucketIds]: CardBucketData;
};

export enum CoinBucketIds {
    SRC = 'c-src',
    DEST_A = 'c-a',
    DEST_B = 'c-b',
    DEST_C = 'c-c',
}

export type CoinBucketData = {
    coins: CoinData[];
    maxCoins: number;
    correctValue: number;
    width: number;
    associatedCardBucket: CardBucketIds;
    modalContent: ModalData;
    isCorrect?: boolean;
};

export type CoinBuckets = {
    [k in CoinBucketIds]: CoinBucketData;
};

export type QuestionData = {
    text: MultiLanguageString;
    answers: string[];
    correctAnswer: string;
    userAnswer?: string;
    isCorrect?: boolean;
    source?: MultiLanguageString;
};

export type LangData = {
    value: LanguageKeys;
    label: string;
};

export enum GameState {
    /** CARD GAME **/
    CARDS_INIT,
    CARDS_MOVED,
    CARDS_ALL_MOVED,
    CARDS_CHECKED,
    CARDS_DONE,
    /** COIN GAME **/
    COINS_INIT,
    COINS_MOVED,
    COINS_ALL_MOVED,
    COINS_CHECKED,
    COINS_DONE,
    /** QUESTION GAME **/
    QUESTION_INIT,
    QUESTION_ANSWERED,
    QUESTION_ALL_ANSWERED,
    QUESTION_CHECKED,
    QUESTION_DONE,
    /** GAME RECAP **/
    GAME_RECAP,
}

export type AppState = {
    cardBuckets: CardBuckets;
    coinBuckets: CoinBuckets;
    modalOpen: boolean;
    modalContent: ModalData;
    currentScreen: number;
    swiper?: Swiper;
    cardGameState: GameState;
    coinGameState: GameState;
    questionGameState: GameState;
    questionGameActiveBucket?: CardBucketIds;
    lang: LanguageKeys;
};

export enum AppActions {
    MOVE_CARD = 'MOVE_CARD',
    MOVE_COIN = 'MOVE_COIN',
    REORDER_CARDS = 'REORDER_CARDS',
    CHECK_CARDS = 'CHECK_CARDS',
    CHECK_COINS = 'CHECK_COINS',
    CHECK_QUESTIONS = 'CHECK_QUESTIONS',
    CARDS_RESET = 'CARDS_RESET',
    MODAL_OPEN = 'MODAL_OPEN',
    MODAL_CLOSE = 'MODAL_CLOSE',
    NEXT_SCREEN = 'NEXT_SCREEN',
    PREV_SCREEN = 'PREV_SCREEN',
    SET_SWIPER = 'SET_SWIPER',
    USER_ANSWER = 'USER_ANSWER',
    SET_LANG = 'SET_LANG',
}

export type AppAction =
    | {
          type: AppActions.CHECK_CARDS;
      }
    | {
          type: AppActions.CHECK_COINS;
      }
    | {
          type: AppActions.CARDS_RESET;
      }
    | {
          type: AppActions.MOVE_CARD;
          bucketIdFrom: string;
          bucketIdTo: string;
          idxFrom: number;
          idxTo: number;
      }
    | {
          type: AppActions.REORDER_CARDS;
          bucketId: string;
          idxFrom: number;
          idxTo: number;
      }
    | {
          type: AppActions.MOVE_COIN;
          bucketIdFrom: string;
          bucketIdTo: string;
          idxFrom: number;
          idxTo: number;
      }
    | {
          type: AppActions.MODAL_OPEN;
          content: ModalData;
      }
    | { type: AppActions.MODAL_CLOSE }
    | {
          type: AppActions.NEXT_SCREEN;
      }
    | {
          type: AppActions.PREV_SCREEN;
      }
    | {
          type: AppActions.SET_SWIPER;
          swiper: Swiper;
      }
    | {
          type: AppActions.USER_ANSWER;
          bucketId: CardBucketIds;
          questionIdx: number;
          answerIdx: number;
      }
    | {
          type: AppActions.CHECK_QUESTIONS;
          bucketId: CardBucketIds;
      }
    | {
          type: AppActions.SET_LANG;
          lang: LanguageKeys;
      };

export enum Strings {
    IDLE_TITLE,
    IDLE_INTRO,
    IDLE_DESCRIPTION,
    IDLE_CTA,
    INTRO_TITLE,
    INTRO_DESCRIPTION,
    INTRO_CTA,

    GAME_CONTROLS_CHECK,
    GAME_CONTROLS_NEXT,
    GAME_CONTROLS_FINISH,

    GAME_DRAG_CARD_TITLE,
    GAME_DRAG_CARD_DESCRIPTION,
    GAME_DRAG_CARD_DESCRIPTION_STATIC,
    GAME_DRAG_CARD_BEGIN,
    GAME_DRAG_CARD_MORE,
    GAME_DRAG_CARD_RESULT,
    GAME_DRAG_CARD_RESULT_COMMENT,

    GAME_DRAG_COIN_TITLE,
    GAME_DRAG_COIN_DESCRIPTION,
    GAME_DRAG_COIN_DESCRIPTION_STATIC,
    GAME_DRAG_COIN_BEGIN,
    GAME_DRAG_COIN_MORE,
    GAME_DRAG_COIN_RESULT,
    GAME_DRAG_COIN_RESULT_COMMENT,

    GAME_QUESTIONS_TITLE,
    GAME_QUESTIONS_DESCRIPTION,
    GAME_QUESTIONS_INSTRUCTION,
    GAME_QUESTIONS_RESULT_CORRECT,
    GAME_QUESTIONS_RESULT_WRONG,

    GAME_RECAP_TITLE,
    GAME_RECAP_DESCRIPTION,
    GAME_RECAP_NEXT,

    OUTRO_TITLE,
    OUTRO_INTRO,
    OUTRO_CTA,
    OUTRO_URL,
}

export type TranslatableUIStrings = {
    [l in LanguageKeys]: {
        [k in Strings]: string;
    };
};

export enum TranslationKeys {
    idle_title = 'idle_title',
    idle_intro = 'idle_intro',
    idle_description = 'idle_description',
    idle_cta = 'idle_cta',
    // intro_title = 'intro_title',
    // intro_description = 'intro_description',
    // intro_cta = 'intro_cta',
    game_controls_check = 'game_controls_check',
    game_controls_next = 'game_controls_next',
    game_controls_finish = 'game_controls_finish',
    game_drag_card_title = 'game_drag_card_title',
    game_drag_card_description = 'game_drag_card_description',
    game_drag_card_description_static = 'game_drag_card_description_static',
    game_drag_card_begin = 'game_drag_card_begin',
    game_drag_card_more = 'game_drag_card_more',
    game_drag_card_result = 'game_drag_card_result',
    game_drag_card_result_comment = 'game_drag_card_result_comment',
    game_drag_coin_title = 'game_drag_coin_title',
    game_drag_coin_description = 'game_drag_coin_description',
    game_drag_coin_description_static = 'game_drag_coin_description_static',
    game_drag_coin_begin = 'game_drag_coin_begin',
    game_drag_coin_more = 'game_drag_coin_more',
    game_drag_coin_result = 'game_drag_coin_result',
    game_drag_coin_result_comment = 'game_drag_coin_result_comment',
    game_questions_title = 'game_questions_title',
    game_questions_description = 'game_questions_description',
    game_questions_instruction = 'game_questions_instruction',
    game_questions_result_correct = 'game_questions_result_correct',
    game_questions_result_wrong = 'game_questions_result_wrong',
    // game_recap_title = 'game_recap_title',
    // game_recap_description = 'game_recap_description',
    // game_recap_next = 'game_recap_next',
    outro_title = 'outro_title',
    outro_intro = 'outro_intro',
    // outro_cta = 'outro_cta',
    // outro_url = 'outro_url',
    data_card_0_content = 'data_card_0_content',
    data_card_0_modal_title = 'data_card_0_modal_title',
    data_card_0_modal_content = 'data_card_0_modal_content',
    data_card_1_content = 'data_card_1_content',
    data_card_1_modal_title = 'data_card_1_modal_title',
    data_card_1_modal_content = 'data_card_1_modal_content',
    data_card_2_content = 'data_card_2_content',
    data_card_2_modal_title = 'data_card_2_modal_title',
    data_card_2_modal_content = 'data_card_2_modal_content',
    data_card_3_content = 'data_card_3_content',
    data_card_3_modal_title = 'data_card_3_modal_title',
    data_card_3_modal_content = 'data_card_3_modal_content',
    data_card_4_content = 'data_card_4_content',
    data_card_4_modal_title = 'data_card_4_modal_title',
    data_card_4_modal_content = 'data_card_4_modal_content',
    data_card_5_content = 'data_card_5_content',
    data_card_5_modal_title = 'data_card_5_modal_title',
    data_card_5_modal_content = 'data_card_5_modal_content',
    data_card_6_content = 'data_card_6_content',
    data_card_6_modal_title = 'data_card_6_modal_title',
    data_card_6_modal_content = 'data_card_6_modal_content',
    data_card_7_content = 'data_card_7_content',
    data_card_7_modal_title = 'data_card_7_modal_title',
    data_card_7_modal_content = 'data_card_7_modal_content',
    data_card_8_content = 'data_card_8_content',
    data_card_8_modal_title = 'data_card_8_modal_title',
    data_card_8_modal_content = 'data_card_8_modal_content',
    data_card_9_content = 'data_card_9_content',
    data_card_9_modal_title = 'data_card_9_modal_title',
    data_card_9_modal_content = 'data_card_9_modal_content',
    data_card_10_content = 'data_card_10_content',
    data_card_10_modal_title = 'data_card_10_modal_title',
    data_card_10_modal_content = 'data_card_10_modal_content',
    data_card_11_content = 'data_card_11_content',
    data_card_11_modal_title = 'data_card_11_modal_title',
    data_card_11_modal_content = 'data_card_11_modal_content',
    data_bucket_a_title = 'data_bucket_a_title',
    data_bucket_a_instruction = 'data_bucket_a_instruction',
    data_bucket_a_question_a_text = 'data_bucket_a_question_a_text',
    data_bucket_a_question_a_source = 'data_bucket_a_question_a_source',
    // data_bucket_a_question_b_text = 'data_bucket_a_question_b_text',
    // data_bucket_a_question_b_source = 'data_bucket_a_question_b_source',
    data_bucket_a_outro_modal_subtitle = 'data_bucket_a_outro_modal_subtitle',
    data_bucket_a_outro_modal_title = 'data_bucket_a_outro_modal_title',
    data_bucket_a_outro_modal_item_a = 'data_bucket_a_outro_modal_item_a',
    data_bucket_a_outro_modal_item_b = 'data_bucket_a_outro_modal_item_b',
    data_bucket_a_outro_modal_item_c = 'data_bucket_a_outro_modal_item_c',
    data_bucket_b_title = 'data_bucket_b_title',
    data_bucket_b_instruction = 'data_bucket_b_instruction',
    data_bucket_b_question_a_text = 'data_bucket_b_question_a_text',
    data_bucket_b_question_a_source = 'data_bucket_b_question_a_source',
    // data_bucket_b_question_b_text = 'data_bucket_b_question_b_text',
    // data_bucket_b_question_b_source = 'data_bucket_b_question_b_source',
    data_bucket_b_outro_modal_subtitle = 'data_bucket_b_outro_modal_subtitle',
    data_bucket_b_outro_modal_title = 'data_bucket_b_outro_modal_title',
    data_bucket_b_outro_modal_item_a = 'data_bucket_b_outro_modal_item_a',
    data_bucket_b_outro_modal_item_b = 'data_bucket_b_outro_modal_item_b',
    data_bucket_b_outro_modal_item_c = 'data_bucket_b_outro_modal_item_c',
    data_bucket_c_title = 'data_bucket_c_title',
    data_bucket_c_instruction = 'data_bucket_c_instruction',
    data_bucket_c_question_a_text = 'data_bucket_c_question_a_text',
    data_bucket_c_question_a_source = 'data_bucket_c_question_a_source',
    // data_bucket_c_question_b_text = 'data_bucket_c_question_b_text',
    // data_bucket_c_question_b_source = 'data_bucket_c_question_b_source',
    data_bucket_c_outro_modal_subtitle = 'data_bucket_c_outro_modal_subtitle',
    data_bucket_c_outro_modal_title = 'data_bucket_c_outro_modal_title',
    data_bucket_c_outro_modal_item_a = 'data_bucket_c_outro_modal_item_a',
    data_bucket_c_outro_modal_item_b = 'data_bucket_c_outro_modal_item_b',
    data_bucket_c_outro_modal_item_c = 'data_bucket_c_outro_modal_item_c',
    data_coin_bucket_a_title = 'data_coin_bucket_a_title',
    data_coin_bucket_a_content = 'data_coin_bucket_a_content',
    data_coin_bucket_b_title = 'data_coin_bucket_b_title',
    data_coin_bucket_b_content = 'data_coin_bucket_b_content',
    data_coin_bucket_c_title = 'data_coin_bucket_c_title',
    data_coin_bucket_c_content = 'data_coin_bucket_c_content',
}
