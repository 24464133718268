import AppString from '../../components/String';
import React, { Dispatch, ReactElement } from 'react';
import {
    AppAction,
    CardBuckets,
    GameState,
    CoinBuckets,
    LanguageKeys,
    Strings,
} from '../../lib/types';
import Header from '../../components/Header';
import GameControls from '../../components/GameControls';
import CardBucketStatic from '../../components/CardBucketStatic';
import CoinBucketStatic from '../../components/CoinBucketStatic';
import './style.scss';

const GameCoinStatic = ({
    gameState,
    cardDestBuckets,
    coinDestBuckets,
    dispatch,
    onNext,
    onBack,
    onHome,
    // onLang,
    lang,
}: {
    gameState: GameState;
    cardDestBuckets: CardBuckets;
    coinDestBuckets: CoinBuckets;
    dispatch: Dispatch<AppAction>;
    onNext: () => void;
    onBack: () => void;
    onHome: () => void;
    // onLang: (langData: any) => void;
    lang: LanguageKeys;
}): ReactElement => {
    return (
        <div className="Game GameCoinStatic">
            <div className="container-vertical">
                <div className="container">
                    <Header
                        title={Strings.GAME_DRAG_COIN_TITLE}
                        onHome={onHome}
                        // onLang={onLang}
                        lang={lang}
                        dark={true}
                    />
                    <div className="row">
                        <div className="col">
                            <div className="intro-text">
                                <p>
                                    <AppString
                                        k={
                                            Strings.GAME_DRAG_COIN_DESCRIPTION_STATIC
                                        }
                                        lang={lang}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Game__wrapper">
                    {/*<div className="Game__src-wrapper">*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="Game__container-inner">*/}
                    {/*            <div className="GameCoinStatic-spacer" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="Game__dest-wrapper Game__dest-wrapper--coin">
                        <div className="container">
                            <CoinBucketStatic
                                coinBuckets={coinDestBuckets}
                                dispatch={dispatch}
                            />
                        </div>
                    </div>
                    <div className="Game__dest-wrapper">
                        <div className="container">
                            <div className="Game__container-inner">
                                <CardBucketStatic
                                    cardBuckets={cardDestBuckets}
                                    lang={lang}
                                    dispatch={dispatch}
                                />
                                <GameControls
                                    gameState={gameState}
                                    dispatch={dispatch}
                                    onBack={onBack}
                                    onNext={onNext}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameCoinStatic;
