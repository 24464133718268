import React, {Dispatch, Fragment, ReactElement} from 'react';
import {
    AppAction,
    AppActions,
    CardBucketIds,
    GameState,
    LanguageKeys,
    QuestionData,
} from '../../lib/types';
import './style.scss';

const Question = ({
    question,
    dispatch,
    bucketId,
    questionIdx,
    gameState,
    lang,
}: {
    question: QuestionData;
    dispatch: Dispatch<AppAction>;
    bucketId: CardBucketIds;
    questionIdx: number;
    gameState: GameState;
    lang: LanguageKeys;
}): ReactElement => {
    const [before, after] = question.text[lang].split('%s');
    const isQuestionsChecked = gameState >= GameState.QUESTION_CHECKED;

    return (
        <div
            className={
                'Question' + (isQuestionsChecked ? ' Question--checked' : '')
            }
        >
            <p>
                <span>{before}</span>
                {question.answers.map((answer, idx) => {
                    let questionClass =
                        question.userAnswer !== answer
                            ? 'Question__option'
                            : 'Question__option Question__option--selected';

                    if (isQuestionsChecked) {
                        const isRightAnswer = answer === question.correctAnswer;
                        if (isRightAnswer)
                            questionClass += ' Question__option--selected ';
                        questionClass +=
                            question.isCorrect || isRightAnswer
                                ? ' Question__option--correct'
                                : ' Question__option--wrong';
                    }

                    return (
                        <Fragment key={idx}>
                            <br />
                            <span
                                onClick={() =>
                                    !isQuestionsChecked &&
                                    dispatch({
                                        type: AppActions.USER_ANSWER,
                                        bucketId: bucketId,
                                        questionIdx: questionIdx,
                                        answerIdx: idx,
                                    })
                                }
                                className={questionClass}
                                role="button"
                            >
                                {answer}
                            </span>
                        </Fragment>
                    );
                })}
                <span>{after}</span>
            </p>
            {question.source && (
                <p className="Question__source">{question.source[lang]}</p>
            )}
        </div>
    );
};

export default Question;
