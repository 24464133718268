import AppString from '../../components/String';
import React, { Dispatch, ReactElement } from 'react';
import {
    AppAction,
    CardBuckets,
    CoinBuckets,
    CardBucketIds,
    LanguageKeys,
    Strings,
} from '../../lib/types';
import Header from '../../components/Header';
import GameControls from '../../components/GameControls';
import CoinBucketStatic from '../../components/CoinBucketStatic';
import './style.scss';
import CardBucketQuestions from '../../components/CardBucketQuestions';

const GameQuestions = ({
    activeCardBucketId,
    cardDestBuckets,
    coinDestBuckets,
    dispatch,
    onNext,
    onBack,
    onHome,
    // onLang,
    lang,
}: {
    activeCardBucketId: CardBucketIds;
    cardDestBuckets: CardBuckets;
    coinDestBuckets: CoinBuckets;
    dispatch: Dispatch<AppAction>;
    onNext: () => void;
    onBack: () => void;
    onHome: () => void;
    // onLang: (langData: any) => void;
    lang: LanguageKeys;
}): ReactElement => {
    return (
        <div className="Game GameQuestions">
            <div className="container-vertical">
                <div className="container">
                    <Header
                        title={Strings.GAME_QUESTIONS_TITLE}
                        onHome={onHome}
                        // onLang={onLang}
                        lang={lang}
                        dark={true}
                    />
                    <div className="row">
                        <div className="col">
                            <div className="intro-text">
                                <p>
                                    <AppString
                                        k={Strings.GAME_QUESTIONS_DESCRIPTION}
                                        lang={lang}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="Game__src-wrapper">*/}
                {/*    <div className="container">*/}
                {/*        <div className="Game__container-inner">*/}
                {/*            <div className="GameQuestions-spacer" />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="Game__dest-wrapper Game__dest-wrapper--coin">
                    <div className="container">
                        <CoinBucketStatic
                            coinBuckets={coinDestBuckets}
                            dispatch={dispatch}
                            activeCardBucketId={activeCardBucketId}
                        />
                    </div>
                </div>
                <div className="Game__dest-wrapper">
                    <div className="container">
                        <div className="Game__container-inner">
                            <CardBucketQuestions
                                cardBuckets={cardDestBuckets}
                                dispatch={dispatch}
                                activeCardBucketId={activeCardBucketId}
                                lang={lang}
                            />
                            <GameControls
                                gameState={
                                    cardDestBuckets[activeCardBucketId]
                                        .questionGameState
                                }
                                dispatch={dispatch}
                                onBack={onBack}
                                onNext={onNext}
                                bucketId={activeCardBucketId}
                                lang={lang}
                                questionData={
                                    cardDestBuckets[activeCardBucketId]
                                        .questions[0] // This works for some mysterious reason.
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameQuestions;
