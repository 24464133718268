import React, { Dispatch, ReactElement } from 'react';
import {
    AppAction,
    AppActions,
    CardBucketIds,
    CardData,
    LanguageKeys,
} from '../../lib/types';
import './style.scss';

const CardStatic = ({
    cardData,
    lang,
    dispatch,
}: {
    cardData: CardData;
    lang: LanguageKeys;
    dispatch: Dispatch<AppAction>;
}): ReactElement => {
    const innerClass =
        'Card__inner' +
        (cardData.allowedBuckets.includes(CardBucketIds.SRC)
            ? ''
            : ' CardStatic__inner--correct');

    const cardContent = (cardData.content ? cardData.content[lang] : '')
        .split('\n')
        .join('<br />');

    return (
        <div
            className="Card CardStatic"
            onClick={() =>
                dispatch({
                    type: AppActions.MODAL_OPEN,
                    content: cardData.modalContent,
                })
            }
        >
            <div className={innerClass}>
                <span role="button" className="Card__info-btn">
                    i
                </span>
                <div className={'Card__icon icon icon-' + cardData.id} />
                <h2
                    className="Card__caption"
                    dangerouslySetInnerHTML={{ __html: cardContent }}
                />
            </div>
        </div>
    );
};

export default CardStatic;
