import { LanguageKeys, Strings, TranslatableUIStrings } from './lib/types';
import en from './lang/en';
// import fr from './lang/fr';
// import de from './lang/de';
// import it from './lang/it';
// import es from './lang/es';
// import pt from './lang/pt';

export default {
    [LanguageKeys.EN]: {
        // Idle screen
        [Strings.IDLE_TITLE]: en.idle_title,
        [Strings.IDLE_INTRO]: en.idle_intro,
        [Strings.IDLE_DESCRIPTION]: en.idle_description,
        [Strings.IDLE_CTA]: en.idle_cta,
        // Intro screen
        // [Strings.INTRO_TITLE]: en.intro_title,
        // [Strings.INTRO_DESCRIPTION]: en.intro_description,
        // [Strings.INTRO_CTA]: en.intro_cta,
        // Shared game controls
        [Strings.GAME_CONTROLS_CHECK]: en.game_controls_check,
        [Strings.GAME_CONTROLS_NEXT]: en.game_controls_next,
        [Strings.GAME_CONTROLS_FINISH]: en.game_controls_finish,
        // Game screen - cards
        [Strings.GAME_DRAG_CARD_TITLE]: en.game_drag_card_title,
        [Strings.GAME_DRAG_CARD_DESCRIPTION]: en.game_drag_card_description,
        [Strings.GAME_DRAG_CARD_DESCRIPTION_STATIC]:
            en.game_drag_card_description_static,
        [Strings.GAME_DRAG_CARD_BEGIN]: en.game_drag_card_begin,
        [Strings.GAME_DRAG_CARD_MORE]: en.game_drag_card_more,
        [Strings.GAME_DRAG_CARD_RESULT]: en.game_drag_card_result,
        [Strings.GAME_DRAG_CARD_RESULT_COMMENT]:
            en.game_drag_card_result_comment,
        // Game screen - coins
        [Strings.GAME_DRAG_COIN_TITLE]: en.game_drag_coin_title,
        [Strings.GAME_DRAG_COIN_DESCRIPTION]: en.game_drag_coin_description,
        [Strings.GAME_DRAG_COIN_DESCRIPTION_STATIC]:
            en.game_drag_coin_description_static,
        [Strings.GAME_DRAG_COIN_BEGIN]: en.game_drag_coin_begin,
        [Strings.GAME_DRAG_COIN_MORE]: en.game_drag_coin_more,
        [Strings.GAME_DRAG_COIN_RESULT]: en.game_drag_coin_result,
        [Strings.GAME_DRAG_COIN_RESULT_COMMENT]:
            en.game_drag_coin_result_comment,
        // Game screen - questions
        [Strings.GAME_QUESTIONS_TITLE]: en.game_questions_title,
        [Strings.GAME_QUESTIONS_DESCRIPTION]: en.game_questions_description,
        [Strings.GAME_QUESTIONS_INSTRUCTION]: en.game_questions_instruction,
        [Strings.GAME_QUESTIONS_RESULT_CORRECT]:
            en.game_questions_result_correct,
        [Strings.GAME_QUESTIONS_RESULT_WRONG]: en.game_questions_result_wrong,
        // Recap, a copy of game coin static
        // [Strings.GAME_RECAP_TITLE]: en.game_recap_title,
        // [Strings.GAME_RECAP_DESCRIPTION]: en.game_recap_description,
        // [Strings.GAME_RECAP_NEXT]: en.game_recap_next,
        // Outro
        [Strings.OUTRO_TITLE]: en.outro_title,
        [Strings.OUTRO_INTRO]: en.outro_intro,
        // [Strings.OUTRO_CTA]: en.outro_cta,
        // [Strings.OUTRO_URL]: en.outro_url,
    },
} as TranslatableUIStrings;
