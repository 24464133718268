import React, { Dispatch, ReactElement } from 'react';
import {
    AppAction,
    CardBucketIds,
    CardBuckets,
    LanguageKeys,
} from '../../lib/types';
import './style.scss';
import Question from '../Question';

const CardBucketQuestions = ({
    cardBuckets,
    dispatch,
    activeCardBucketId,
    lang,
}: {
    cardBuckets: CardBuckets;
    dispatch: Dispatch<AppAction>;
    isDragDisabled?: boolean;
    activeCardBucketId?: CardBucketIds;
    lang: LanguageKeys;
}): ReactElement => {
    return (
        <div className="CardBucketQuestions">
            <div className="CardBucket__wrapper">
                {Object.keys(cardBuckets).map((k: string) => {
                    const bucket = cardBuckets[k as CardBucketIds];
                    const bucketClass =
                        'CardBucket__wrapper-single' +
                        (undefined !== activeCardBucketId &&
                        k !== activeCardBucketId
                            ? ' CardBucketQuestions__wrapper-single--inactive'
                            : '');

                    return (
                        <div
                            key={k}
                            style={{
                                flexBasis:
                                    1.25 + bucket.maxCards * 5.65 + 'rem', // See Card/style.scss
                            }}
                            className={bucketClass}
                        >
                            <h2
                                className="CardBucket__title"
                                dangerouslySetInnerHTML={{
                                    __html: bucket.title
                                        ? bucket.title[lang]
                                        : '',
                                }}
                            />
                            <span className="CardBucket__title-decoration" />
                        </div>
                    );
                })}
            </div>
            {activeCardBucketId && (
                <div className="CardBucketQuestions__questions row">
                    {cardBuckets[activeCardBucketId].questions.map(
                        (question, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="col-12 CardBucketQuestions__question-wrapper"
                                >
                                    <Question
                                        dispatch={dispatch}
                                        question={question}
                                        bucketId={activeCardBucketId}
                                        questionIdx={idx}
                                        gameState={
                                            cardBuckets[activeCardBucketId]
                                                .questionGameState
                                        }
                                        lang={lang}
                                    />
                                </div>
                            );
                        },
                    )}
                </div>
            )}
        </div>
    );
};

export default CardBucketQuestions;
