import React, { ReactElement } from 'react';
import {
    Draggable,
    DraggableProvided,
    DraggableRubric,
    DraggableStateSnapshot,
    Droppable,
} from 'react-beautiful-dnd';
import {
    BucketTypes,
    CoinBucketIds,
    CoinBuckets,
    CoinData,
} from '../../lib/types';
import Coin from '../Coin';
import './style.scss';
import { getCoinBucketStyle } from '../../lib/styling';

const CoinBucket = ({
    coinBuckets,
    isDragDisabled,
}: {
    coinBuckets: CoinBuckets;
    isDragDisabled: boolean;
}): ReactElement => {
    return (
        <div className="CoinBucket__wrapper">
            {Object.keys(coinBuckets).map((k: string) => {
                const coinBucket = coinBuckets[k as CoinBucketIds];
                const coins = coinBucket.coins;

                const bucketClass =
                    'CoinBucket__inner' +
                    (undefined === coinBucket.isCorrect
                        ? ''
                        : coinBucket.isCorrect
                        ? ' CoinBucket__inner--correct'
                        : ' CoinBucket__inner--wrong');

                return (
                    <div
                        key={k}
                        style={{
                            flexBasis: 1.25 + coinBucket.width * 5.65 + 'rem', // See Card/style.scss
                            // width: 100 * coinBucket.width + '%', // See Card/style.scss
                        }}
                        className="CoinBucket__wrapper-single"
                    >
                        <Droppable
                            key={k}
                            droppableId={k}
                            renderClone={(
                                provided: DraggableProvided,
                                snapshot: DraggableStateSnapshot,
                                rubric: DraggableRubric,
                            ) => (
                                <Coin
                                    coinData={coins[rubric.source.index]}
                                    key={coins[rubric.source.index].id}
                                    provided={provided}
                                    snapshot={snapshot}
                                />
                            )}
                            direction="horizontal"
                            type={BucketTypes.COIN}
                        >
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        ref={provided.innerRef}
                                        style={getCoinBucketStyle(
                                            snapshot.isDraggingOver,
                                        )}
                                        {...provided.droppableProps}
                                        className={bucketClass}
                                    >
                                        {coins.map(
                                            (
                                                coinData: CoinData,
                                                index: number,
                                            ) => (
                                                <Draggable
                                                    key={coinData.id}
                                                    draggableId={coinData.id}
                                                    index={index}
                                                    isDragDisabled={
                                                        isDragDisabled
                                                    }
                                                >
                                                    {(provided, snapshot) => (
                                                        <Coin
                                                            coinData={coinData}
                                                            key={coinData.id}
                                                            provided={provided}
                                                            snapshot={snapshot}
                                                        />
                                                    )}
                                                </Draggable>
                                            ),
                                        )}
                                        <span
                                            style={{
                                                display: 'none',
                                            }}
                                        >
                                            {provided.placeholder}
                                        </span>
                                    </div>
                                );
                            }}
                        </Droppable>
                    </div>
                );
            })}
            <span className="CoinBucket__value">
                {Object.keys(coinBuckets).reduce((acc, k) => {
                    return (
                        acc +
                        coinBuckets[k as CoinBucketIds].coins.reduce(
                            (accCoin, c) => {
                                return accCoin + c.value;
                            },
                            0,
                        )
                    );
                }, 0)}
                %
            </span>
        </div>
    );
};

export default CoinBucket;
