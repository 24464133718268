import {
    CardBucketIds,
    CardBuckets,
    CardData,
    CoinBucketIds,
    CoinBuckets,
    CoinData,
    GameState,
    LangData,
    LanguageKeys,
    ModalData,
    OutroBoxData,
    TranslationKeys,
} from './lib/types';
import {
    cardBucketsWhere,
    coinBucketsWhere,
} from './lib/helpers';
import en from './lang/en';

export const languages: LangData[] = [{ value: LanguageKeys.EN, label: 'EN' }];

function multilang(key: TranslationKeys) {
    return {
        [LanguageKeys.EN]: en[key],
    };
}

export const initCardSrc: CardData[] = [
    {
        id: '0',
        content: multilang(TranslationKeys.data_card_0_content),
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: multilang(TranslationKeys.data_card_0_modal_title),
            content: multilang(TranslationKeys.data_card_0_modal_content),
            image: '0',
        },
    },
    {
        id: '1',
        content: multilang(TranslationKeys.data_card_1_content),
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: multilang(TranslationKeys.data_card_1_modal_title),
            content: multilang(TranslationKeys.data_card_1_modal_content),
            image: '1',
        },
    },
    {
        id: '2',
        content: multilang(TranslationKeys.data_card_2_content),
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: multilang(TranslationKeys.data_card_2_modal_title),
            content: multilang(TranslationKeys.data_card_2_modal_content),
            image: '2',
        },
    },
    {
        id: '3',
        content: multilang(TranslationKeys.data_card_3_content),
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: multilang(TranslationKeys.data_card_3_modal_title),
            content: multilang(TranslationKeys.data_card_3_modal_content),
            image: '3',
        },
    },
    {
        id: '4',
        content: multilang(TranslationKeys.data_card_4_content),
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: multilang(TranslationKeys.data_card_4_modal_title),
            content: multilang(TranslationKeys.data_card_4_modal_content),
            image: '4',
        },
    },
    {
        id: '5',
        content: multilang(TranslationKeys.data_card_5_content),
        allowedBuckets: [CardBucketIds.DEST_A],
        modalContent: {
            title: multilang(TranslationKeys.data_card_5_modal_title),
            content: multilang(TranslationKeys.data_card_5_modal_content),
            image: '5',
        },
    },
    {
        id: '6',
        content: multilang(TranslationKeys.data_card_6_content),
        allowedBuckets: [CardBucketIds.DEST_B],
        modalContent: {
            title: multilang(TranslationKeys.data_card_6_modal_title),
            content: multilang(TranslationKeys.data_card_6_modal_content),
            image: '6',
        },
    },
    {
        id: '7',
        content: multilang(TranslationKeys.data_card_7_content),
        allowedBuckets: [CardBucketIds.DEST_B],
        modalContent: {
            title: multilang(TranslationKeys.data_card_7_modal_title),
            content: multilang(TranslationKeys.data_card_7_modal_content),
            image: '7',
        },
    },
    {
        id: '8',
        content: multilang(TranslationKeys.data_card_8_content),
        allowedBuckets: [CardBucketIds.DEST_C],
        modalContent: {
            title: multilang(TranslationKeys.data_card_8_modal_title),
            content: multilang(TranslationKeys.data_card_8_modal_content),
            image: '8',
        },
    },
    {
        id: '9',
        content: multilang(TranslationKeys.data_card_9_content),
        allowedBuckets: [CardBucketIds.DEST_C],
        modalContent: {
            title: multilang(TranslationKeys.data_card_9_modal_title),
            content: multilang(TranslationKeys.data_card_9_modal_content),
            image: '9',
        },
    },
    {
        id: '10',
        content: multilang(TranslationKeys.data_card_10_content),
        allowedBuckets: [CardBucketIds.DEST_C],
        modalContent: {
            title: multilang(TranslationKeys.data_card_10_modal_title),
            content: multilang(TranslationKeys.data_card_10_modal_content),
            image: '10',
        },
    },
    {
        id: '11',
        content: multilang(TranslationKeys.data_card_11_content),
        allowedBuckets: [],
        modalContent: {
            title: multilang(TranslationKeys.data_card_11_modal_title),
            content: multilang(TranslationKeys.data_card_11_modal_content),
            image: '11',
        },
    },
];

export const initCardBuckets = {
    [CardBucketIds.SRC]: {
        cards: initCardSrc,
        maxCards: 12,
        isFull: true,
        questions: [],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {} as OutroBoxData,
    },
    [CardBucketIds.DEST_A]: {
        title: multilang(TranslationKeys.data_bucket_a_title),
        instruction: multilang(TranslationKeys.data_bucket_a_instruction),
        cards: [],
        maxCards: 6,
        isFull: false,
        questions: [
            {
                text: multilang(TranslationKeys.data_bucket_a_question_a_text),
                answers: [
                    'Purchase of natural fibres',
                    'Waste generated at manufacturing plants',
                    'Transportation of products to your facility',
                ],
                correctAnswer: 'Purchase of natural fibres',
                source: multilang(
                    TranslationKeys.data_bucket_a_question_a_source,
                ),
            },
        ],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {
            imgClassName: 'expand-lifespan',
            modalData: {
                subtitle: multilang(
                    TranslationKeys.data_bucket_a_outro_modal_subtitle,
                ),
                title: multilang(
                    TranslationKeys.data_bucket_a_outro_modal_title,
                ),
                isListView: true,
                listItems: [
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_a_outro_modal_item_a,
                        ),
                        icon: 'mobile',
                    },
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_a_outro_modal_item_b,
                        ),
                        icon: 'mask',
                    },
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_a_outro_modal_item_c,
                        ),
                        icon: 'laptop',
                    },
                ],
            },
        } as OutroBoxData,
    },
    [CardBucketIds.DEST_B]: {
        title: multilang(TranslationKeys.data_bucket_b_title),
        instruction: multilang(TranslationKeys.data_bucket_b_instruction),
        cards: [],
        maxCards: 2,
        isFull: false,
        questions: [
            {
                text: multilang(TranslationKeys.data_bucket_b_question_a_text),
                answers: [
                    'Purchasing energy-efficient computer monitors',
                    'Switching to 100% renewable energy',
                    'Upgrade boilers to improve efficiency',
                ],
                correctAnswer: 'Switching to 100% renewable energy',
                source: multilang(
                    TranslationKeys.data_bucket_b_question_a_source,
                ),
            },
        ],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {
            imgClassName: 'limit-use',
            modalData: {
                subtitle: multilang(
                    TranslationKeys.data_bucket_b_outro_modal_subtitle,
                ),
                title: multilang(
                    TranslationKeys.data_bucket_b_outro_modal_title,
                ),
                isListView: true,
                listItems: [
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_b_outro_modal_item_a,
                        ),
                        icon: 'button',
                    },
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_b_outro_modal_item_b,
                        ),
                        icon: 'signal',
                    },
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_b_outro_modal_item_c,
                        ),
                        icon: 'star',
                    },
                ],
            },
        } as OutroBoxData,
    },
    [CardBucketIds.DEST_C]: {
        title: multilang(TranslationKeys.data_bucket_c_title),
        instruction: multilang(TranslationKeys.data_bucket_c_instruction),
        cards: [],
        maxCards: 3,
        isFull: false,
        questions: [
            {
                text: multilang(TranslationKeys.data_bucket_c_question_a_text),
                answers: [
                    "The transportation of products from retail stores to consumers' homes.",
                    'The incineration of the product at the end of its useful life.',
                    "The product's use by the consumer (washing, drying, ironing)",
                ],
                correctAnswer:
                    "The product's use by the consumer (washing, drying, ironing)",
                source: multilang(
                    TranslationKeys.data_bucket_c_question_a_source,
                ),
            },
        ],
        questionGameState: GameState.QUESTION_INIT,
        outroBox: {
            imgClassName: 'limit-weight',
            modalData: {
                subtitle: multilang(
                    TranslationKeys.data_bucket_c_outro_modal_subtitle,
                ),
                title: multilang(
                    TranslationKeys.data_bucket_c_outro_modal_title,
                ),
                isListView: true,
                listItems: [
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_c_outro_modal_item_a,
                        ),
                        icon: 'signal',
                    },
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_c_outro_modal_item_b,
                        ),
                        icon: 'clip',
                    },
                    {
                        item: multilang(
                            TranslationKeys.data_bucket_c_outro_modal_item_c,
                        ),
                        icon: 'screen',
                    },
                ],
            },
        } as OutroBoxData,
    },
} as CardBuckets;

export const correctCardBuckets = Object.keys(initCardBuckets).reduce(
    (acc, k) => {
        acc[k as CardBucketIds] = {
            ...initCardBuckets[k as CardBucketIds],
            cards: initCardSrc.filter((c) => {
                return c.allowedBuckets.includes(k as CardBucketIds);
            }),
        };
        return acc;
    },
    {} as CardBuckets,
);

export const correctCardSrcBuckets = cardBucketsWhere(correctCardBuckets, [
    CardBucketIds.SRC,
]);
export const correctCardDestBuckets = cardBucketsWhere(correctCardBuckets, [
    CardBucketIds.DEST_A,
    CardBucketIds.DEST_B,
    CardBucketIds.DEST_C,
]);

const makeCoins = (values: number[] = []): CoinData[] =>
    values.map((value, idx) => ({
        id: 'coin-' + idx,
        value,
    }));

export const initCoinSrc: CoinData[] = makeCoins([
    5,
    5,
    10,
    10,
    10,
    10,
    10,
    10,
    10,
    10,
    10,
]);

export const initCoinBuckets = {
    [CoinBucketIds.SRC]: {
        coins: initCoinSrc,
        maxCoins: 0,
        correctValue: 0,
        width: initCardBuckets[CardBucketIds.SRC].maxCards,
        associatedCardBucket: CardBucketIds.SRC,
        modalContent: {} as ModalData,
    },
    [CoinBucketIds.DEST_A]: {
        coins: [],
        maxCoins: 11,
        correctValue: 70,
        width: initCardBuckets[CardBucketIds.DEST_A].maxCards,
        associatedCardBucket: CardBucketIds.DEST_A,
        modalContent: {
            title: multilang(TranslationKeys.data_coin_bucket_a_title),
            content: multilang(TranslationKeys.data_coin_bucket_a_content),
            image: '0',
        },
    },
    [CoinBucketIds.DEST_B]: {
        coins: [],
        maxCoins: 11,
        correctValue: 5,
        width: initCardBuckets[CardBucketIds.DEST_B].maxCards,
        associatedCardBucket: CardBucketIds.DEST_B,
        modalContent: {
            title: multilang(TranslationKeys.data_coin_bucket_b_title),
            content: multilang(TranslationKeys.data_coin_bucket_b_content),
            image: '7',
        },
    },
    [CoinBucketIds.DEST_C]: {
        coins: [],
        maxCoins: 11,
        correctValue: 25,
        width: initCardBuckets[CardBucketIds.DEST_C].maxCards,
        associatedCardBucket: CardBucketIds.DEST_C,
        modalContent: {
            title: multilang(TranslationKeys.data_coin_bucket_c_title),
            content: multilang(TranslationKeys.data_coin_bucket_c_content),
            image: '8',
        },
    },
} as CoinBuckets;

// NO NEED TO CHANGE STUFF AFTER THIS.

// Helper function to create indexes & split
// original coin bucket data based on how many each bucket expects.
// const correctCoinBucketIds = Object.keys(initCoinBuckets)
//     .map((k) => initCoinBuckets[k as CoinBucketIds].correctValue)
//     .map(cumulativeSum)
//     .map((n) => Math.round(n / 10))
//     .reduce((acc, cur, idx, arr) => {
//         if (0 === idx) {
//             acc.push([0, arr[idx]]);
//         }
//         if (idx < arr.length - 1) {
//             acc.push(arr.slice(idx, idx + 2));
//         }
//         return acc;
//     }, [] as number[][]);

// Applying the helper function above.
// const correctCoinBucketData = correctCoinBucketIds.map((ids) => {
//     const [a, b] = ids;
//     return initCoinSrc.slice(a, b);
// });

const correctCoinBucketData: CoinData[][] = [
    makeCoins([]),
    makeCoins([10, 10, 10, 10, 10, 10, 10]),
    makeCoins([5]),
    makeCoins([10, 10, 5]),
];

export const correctCoinBuckets = Object.keys(initCoinBuckets).reduce(
    (acc, k, idx) => {
        acc[k as CoinBucketIds] = {
            ...initCoinBuckets[k as CoinBucketIds],
            coins: correctCoinBucketData[idx],
        };
        return acc;
    },
    {} as CoinBuckets,
);

export const correctCoinDestBuckets = coinBucketsWhere(correctCoinBuckets, [
    CoinBucketIds.DEST_A,
    CoinBucketIds.DEST_B,
    CoinBucketIds.DEST_C,
]);
