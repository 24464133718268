import React, { ReactElement } from 'react';
import strings from '../../strings';
import { LanguageKeys, Strings } from '../../lib/types';

const AppString = ({
    k,
    lang,
}: {
    k: Strings;
    lang: LanguageKeys;
}): ReactElement => {
    const out = strings[lang][k].split('\n') || ['TODO'];
    return (
        <>
            {out.map((item, key) => {
                return (
                    <span key={key}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: item,
                            }}
                        />
                        {out.length > 1 && <br />}
                    </span>
                );
            })}
        </>
    );
};

export default AppString;
